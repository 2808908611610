import React, { useEffect, useMemo, useState } from 'react'
import { BasketActionType, BasketContext } from '../../Contexts/basket/context'
import './style.scss'
import { Navigate } from 'react-router-dom'
import { ProductTab } from './Components/ProductTab'
import { CheckoutTab } from './Components/CheckoutTab'
import { SubmitModal } from './Components/SubmitModal'
import { Slugs } from '../../utils/constants'
import { getBasketWithDiscounts } from './actions'
import useToken from '../../Hooks/useToken'
import { getAllCatalogs } from '../HomeView/actions'
import { useHandleError } from '../../Hooks/useHandleError'
import { Catalog } from '../../Types/Catalog'
import { getBusinessConditionSteps } from '../ShopView/actions'

const CartView = () => {
  const { dispatch, state } = React.useContext(BasketContext)
  const { loginUser } = useToken()
  const handleError = useHandleError()

  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false)

  const [date, setDate] = useState<Date>(new Date())

  useEffect(() => {
    if (state.basket && state.basket.id) {
      getBasketWithDiscounts(state.basket.id, loginUser).then((basket) =>
        dispatch({
          type: BasketActionType.UPDATE_DISCOUNT_BASKET,
          payload: basket,
        })
      )
    }
  }, [state.basket?.id])

  const isEmpty =
    state.basket !== null &&
    (state.basket.basketProducts.length === 0 ||
      state.basket.basketProducts.every((item) => item.amount === 0))

  const handleSubmit = () => {
    setIsSubmitted(true)
  }

  const [totalCatalogs, setTotalCatalogs] = useState<Catalog[]>()
  useEffect(() => {
    getAllCatalogs(loginUser)
      .then((catalogs) => setTotalCatalogs(catalogs))
      .catch(handleError)
  }, [loginUser.access_token])

  const catalogs = useMemo(
    () =>
      totalCatalogs?.filter((catalog) =>
        state.basketWithDiscounts?.basketProducts.find(
          (basket_product) =>
            basket_product.product.businessId === catalog.business.id ||
            (basket_product.product.provider?.catalog?.id === catalog.id &&
              basket_product.product.businessId === catalog.business.id) ||
            basket_product.product.provider?.catalog?.id === catalog.id
        )
      ),
    [totalCatalogs, state.basketWithDiscounts?.basketProducts]
  )

  return (
    <>
      <div>
        <span className="page-title">Panier</span>
        {isEmpty ? (
          <Navigate to={Slugs.HOME} />
        ) : (
          <div className="flex flex-col-reverse sm:flex-row">
            <div className="sm:text-left">
              {state.basketWithDiscounts && (
                <ProductTab
                  basketProducts={state.basketWithDiscounts.basketProducts}
                  catalogs={catalogs}
                />
              )}
            </div>
            <div className="vertical-line hidden sm:block mx-4 opacity-50" />
            <div className="w-full h-0.5 bg-neutral-300 my-5 mb-4.5 sm:hidden"></div>
            <div>
              {state.basketWithDiscounts && (
                <CheckoutTab
                  handleSubmit={handleSubmit}
                  date={date}
                  setDate={setDate}
                  basket={state.basketWithDiscounts}
                />
              )}
            </div>
          </div>
        )}
      </div>

      {state.basketWithDiscounts && (
        <SubmitModal
          show={isSubmitted}
          handleClose={() => setIsSubmitted(false)}
          requestedDate={date}
          catalogs={catalogs}
        />
      )}
    </>
  )
}

export default CartView
