import React, { ChangeEvent, useCallback, useState } from 'react'
import './style.scss'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/images/Philab-logo.png'
import pharmacyPicture from '../../assets/images/pharmacy.jpg'
import { Slugs } from '../../utils/constants'
import ProgressBarPage from './components/ProgressBarPage'
import FormDispensaryNamePage from './components/SignUpDispensaryView/FormDispensaryNamePage'
import FormDispensaryPage from './components/SignUpDispensaryView/FormDispensaryPage'
import FormDispensaryAddressPage from './components/SignUpDispensaryView/FormDispensaryAddressPage'
import {
  signUpDispensary,
  SignUpDispensaryDto,
  uploadSignupRibDoc,
} from './actions'
import useToken from '../../Hooks/useToken'
import { useHandleError } from '../../Hooks/useHandleError'
import useToast from '../../Hooks/useToast'
import Form from 'react-bootstrap/Form'
import play from '../../assets/images/play.svg'

const SignUpView = () => {
  const [inputs, setInputs] = useState<FormValues>({})
  const navigate = useNavigate()

  const { loginUser } = useToken()
  const handleError = useHandleError()

  const [currentStep, setCurrentStep] = useState(0)
  const [ribFileInput, setRibFileInput] = useState<File | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value
    setInputs((values) => ({ ...values, [name]: value }))
  }
  const { showSuccess } = useToast()

  const handleSubmit = () => {
    signUpDispensary(loginUser, inputs as SignUpDispensaryDto)
      .then(() => {
        navigate(Slugs.HOME)
        showSuccess('Un lien de validation vous a été envoyé par email.')
      })
      .catch(handleError)
    if (ribFileInput) {
      console.log('rib file detected')
      console.log('user ', loginUser)
      uploadSignupRibDoc(loginUser, ribFileInput, inputs.email)
        .then(() => {
          showSuccess('RIB téléchargé avec succès')
        })
        .catch(handleError)
    }
  }

  const getCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <FormDispensaryNamePage
            handleChange={handleChange}
            nextStep={() => setCurrentStep(1)}
            values={inputs}
          />
        )
      case 1:
        return (
          <FormDispensaryPage
            handleChange={handleChange}
            nextStep={() => setCurrentStep(2)}
            prevStep={() => setCurrentStep(0)}
            values={inputs}
          />
        )
      case 2:
        return (
          <FormDispensaryAddressPage
            handleChange={handleChange}
            nextStep={handleSubmit}
            prevStep={() => setCurrentStep(1)}
            values={inputs}
          />
        )
    }
  }

  const handleRibFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      setRibFileInput(file)
    }
  }

  const video = React.createRef<HTMLVideoElement>()
  const playVideo = useCallback(() => {
    video.current?.play()
    setPlaying(true)
  }, [])
  const [playing, setPlaying] = useState(false)

  return (
    <div className="w-full h-screen grid sm:grid-cols-2">
      <div className="form-container">
        <img src={logo} alt="logo" className="w-full max-w-sm mx-auto" />
        <div className="w-full px-3">
          <span className="title">
            <b>Inscription</b>
          </span>
          <ProgressBarPage currentStep={currentStep} totalSteps={3} />
          {currentStep >= 1 && (
            <>
              <span>Téléversez votre RIB (.pdf, moins de 5Mo)</span>
              <Form.Control
                type="file"
                accept=".pdf"
                className="login-form-input"
                name="rib"
                onChange={handleRibFileChange}
              />
            </>
          )}
          <form className="login-form mt-1.5">{getCurrentStep()}</form>
          <div className="text-base bg-gray-200 rounded-lg p-3">
            Déjà un compte ?
            <span
              className="link pointer text-primary ml-1"
              onClick={() => navigate(Slugs.LOGIN)}
            >
              Connectez-vous
            </span>
          </div>
        </div>
      </div>
      <div className="max-md:py-4 img-container relative flex justify-center items-center font-sans">
        <img
          src={pharmacyPicture}
          alt="pharmacy"
          className="absolute w-full h-full inset-0 max-md:hidden"
        />
        <div className="absolute w-full bottom-0 h-16 from-transparent to-white bg-gradient-to-b" />
        <div className="w-[calc(100%-30px)] max-w-xl bg-white/30 backdrop-blur-sm rounded shadow text-neutral-700">
          <div className="text-center font-semibold text-xl py-1">
            Comment ça marche ?
          </div>
          <div className="relative aspect-video">
            <video
              ref={video}
              src="/videos/videoPhiLab.mp4"
              className="rounded"
              controls
              preload="auto"
            />
            <div
              onClick={playVideo}
              className={`absolute w-full h-full inset-0 bg-white rounded cursor-pointer ${
                playing ? 'opacity-0 pointer-events-none' : 'opacity-100'
              } transition-opacity duration-500`}
            >
              <img
                src={logo}
                alt="logo"
                className="w-full h-full object-contain absolute inset-0"
              />
              <div className="grid place-items-center w-full h-full absolute inset-0 hover:backdrop-blur-none backdrop-blur-sm transition-all duration-200 group">
                <img
                  src={play}
                  alt="Play"
                  className="w-16 h-16 rounded-full group-hover:scale-110 transition-transform duration-200"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpView

export type FormStepProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  nextStep: () => void
  prevStep?: () => void
  values: FormValues
  onRibFileChange?: (file: File | null) => void
}

export type FormValues = {
  [key: string]: string
}
