import React, { useEffect } from 'react'

import './App.scss'
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import Layout from './Views/_Layout'
import ProfileView from './Views/ProfileView'
import Err404 from './Views/Err404'
import LoginView from './Views/LoginView'
import HomeView from './Views/HomeView'
import DispensaryOrdersView from './Views/DispensaryOrdersView'
import InfoView from './Views/_Statics/InfoView'
import ContactView from './Views/_Statics/ContactView'
import SupplierAdvView from './Views/_Statics/SupplierAdvView'
import PharmaAdvView from './Views/_Statics/PharmaAdvView'
import RequireAuth from './Components/_Functional/RequireAuth'
import { Roles } from './Types/Role'
import SignUpView from './Views/SignUpView/dispensary.index'
import SignUpProviderView from './Views/SignUpView/provider.index'
import CartView from './Views/CartView'
import ShopView from './Views/ShopView'
import ProductView from './Views/ProductView'
import { ToastProvider } from './Contexts/toast/ToastProvider'
import SupplierOrdersView from './Views/SupplierOrdersView/SupplierOrdersView'
import BusinessConditionsView from './Views/BusinessConditions/BusinessConditionsView'
import CGVView from './Views/_Statics/CGVView/CGVView'
import { Slugs } from './utils/constants'
import CreateBusinessConditionView from './Views/BusinessConditions/components/CreateBusinessConditionView'
import UserManagementView from './Views/UserManagementView/UserManagementView'
import AddCatalogView from './Views/AddCatalogView/AddCatalogView'
import { DownloadFactureView } from './Views/DownloadFactureView'
import { Impersonate } from './Components/Impersonate'
import BusinessConditionsDispensariesList from './Views/BusinessConditions/BusinessConditionsDispensariesList'
import AdminOrdersView from './Views/AdminOrdersView/AdminOrdersView'
import ProviderPublicInfoView from './Views/ProviderPublicInfoView'
import StockView from './Views/StockView'
import ResetPasswordView from './Views/ResetPasswordView'

const ROLES: Roles = {
  admin: 0,
  pharma: 1,
  provider: 2,
}

function App() {
  useEffect(() => {
    console.log('Info - App version: 1.0.6')
    console.log(
      "---- Do not type anything in the console if you don't know what you're doing ----"
    )
  }, [])
  return (
    <ToastProvider>
      <Impersonate />

      <Routes>
        <Route
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path={Slugs.SHOP + '/:catalogId'} element={<ShopView />} />

          <Route
            element={
              <RequireAuth
                allowedRoles={[ROLES.admin, ROLES.pharma, ROLES.provider]}
              />
            }
          >
            {/* App */}
            <Route path="/" element={<Navigate to={Slugs.HOME} />} />
            <Route
              path={Slugs.SHOP + '/:catalogId' + Slugs.INFO}
              element={<ProviderPublicInfoView />}
            />

            <Route
              path={Slugs.PRODUCT + '/:productId'}
              element={<ProductView />}
            />
            <Route path={Slugs.PROFILE} element={<ProfileView />} />
          </Route>
          <Route path={Slugs.HOME} element={<HomeView />} />

          {/* pharmacie */}
          <Route element={<RequireAuth allowedRoles={[ROLES.pharma]} />}>
            <Route
              path={Slugs.ORDERS_DISPENSARY}
              element={<DispensaryOrdersView />}
            />
            <Route path={Slugs.STOCK} element={<StockView />} />
            <Route path={Slugs.CART} element={<CartView />} />
          </Route>
          {/* admin */}
          <Route element={<RequireAuth allowedRoles={[ROLES.admin]} />}>
            <Route path={Slugs.ORDERS_ADMIN} element={<AdminOrdersView />} />
            <Route
              path={Slugs.ACCOUNT_MANAGEMENT}
              element={<UserManagementView />}
            />
            <Route
              path={Slugs.AJOUTER_CATALOGUE}
              element={<AddCatalogView />}
            />
          </Route>
          {/* supplier */}
          <Route element={<RequireAuth allowedRoles={[ROLES.provider]} />}>
            <Route
              path={Slugs.ORDERS_PROVIDER}
              element={<SupplierOrdersView />}
            />
            <Route
              path={Slugs.BUSINESS_CONDITIONS}
              element={<BusinessConditionsView />}
            />
            <Route
              path={Slugs.BUSINESS_CONDITIONS_CREATE}
              element={<CreateBusinessConditionView />}
            />
            <Route
              path={Slugs.DISPENSERS}
              element={<BusinessConditionsDispensariesList />}
            />
            <Route
              path={Slugs.BUSINESS_CONDITIONS_EDIT + '/:businessConditionId'}
              element={<CreateBusinessConditionView />}
            />
          </Route>

          {/* statics */}
          <Route path={Slugs.WHO_WE_ARE} element={<InfoView />} />
          <Route path={Slugs.CONTACT} element={<ContactView />} />
          <Route path={Slugs.LEGAL_MENTIONS} element={<CGVView />} />
          <Route path={Slugs.SALES_TERMS} element={<CGVView />} />
          <Route path={Slugs.CGU} element={<CGVView />} />
          <Route path={Slugs.CONDIFENCIALITY} element={<CGVView />} />
          <Route path="avantages-fournisseur" element={<SupplierAdvView />} />
          <Route path="avantages-pharmacien" element={<PharmaAdvView />} />

          {/* catch all */}
          <Route path="*" element={<Err404 />} />
        </Route>
        {/* no layout */}
        <Route path={Slugs.RESET_PASSWORD} element={<ResetPasswordView />} />
        <Route path={Slugs.LOGIN} element={<LoginView />} />
        <Route path={Slugs.REGISTER_DISPENSARY} element={<SignUpView />} />{' '}
        {/*à verifier*/}
        <Route
          path={Slugs.REGISTER_PROVIDER}
          element={<SignUpProviderView />}
        />
        <Route
          path={Slugs.DOWNLOAD_FACTURE}
          element={<DownloadFactureView />}
        />
      </Routes>
    </ToastProvider>
  )
}

export default App
