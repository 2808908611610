import {
  BusinessConditionStep,
  BusinessConditionType,
} from '../../../Types/BusinessCondition'
import React, { useMemo } from 'react'
import { Product } from '../../../Types/Product'

type Props = {
  product: Product
  type: BusinessConditionType
}

const BusinessConditionsByType: React.FC<Props> = ({ product, type }) => {
  const steps = useMemo(() => {
    if (
      !product.businessConditions ||
      !product.businessConditions.length ||
      !product.businessConditions.find((item) => item.type === type)
    )
      return null

    const steps = product.businessConditions
      .filter((bc) => bc.type === type)
      .map((bc) => bc.steps)

    // Reduce the steps to a single array
    const stepsArray = steps.reduce((acc, curr) => acc.concat(curr), [])

    // Sort the steps by minimum value
    return stepsArray
      .sort((a, b) => a.minimumOrderAmount - b.minimumOrderAmount)
      .reduce(
        (list, value) =>
          !list.length ||
          list[list.length - 1].discountRate < value.discountRate
            ? [...list, value]
            : list,
        [] as BusinessConditionStep[]
      )
  }, [type, product])

  if (!steps || steps.length === 0) return null

  return (
    <div className="d-flex flex-column justify-content-start align-items-center text-black">
      <span style={{ fontSize: '1.1rem', marginBottom: '15px' }}>
        {type === BusinessConditionType.SHIPPING_COST
          ? 'Réduction de frais de livraison'
          : 'Réduction de prix unitaire'}
      </span>
      <div className="text-center">
        <div className="row">
          <span className="col">Quantité minimum</span>

          <span className="col">Réduction</span>

          {type === BusinessConditionType.PRODUCT_AMOUNT ? (
            <span className="col">Unité offerte</span>
          ) : null}
        </div>
        <div className="horizontal-divider-1" />
        {steps.map((bc) => (
          <>
            <div className="row" key={bc.id}>
              <span className="col">{bc.minimumOrderAmount}</span>
              <span className="col">{bc.discountRate}%</span>
              {type === BusinessConditionType.PRODUCT_AMOUNT ? (
                <span className="col">{bc.freeUnit}</span>
              ) : null}
            </div>
            <div className="horizontal-divider-1" />
          </>
        ))}
      </div>
    </div>
  )
}

export default BusinessConditionsByType
