import React from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import useAuth from '../../Hooks/useAuth'
import { Role } from '../../Types/Role'
import useToken from '../../Hooks/useToken'
import { getUser } from '../../Views/LoginView/actions'
import { LoadingView } from '../../Views/LoadingView'
import { Slugs } from '../../utils/constants'

type RequireAuthProps = { allowedRoles: Role[] }

function RequireAuth({ allowedRoles }: RequireAuthProps) {
  const auth = useAuth()
  const { token, setToken } = useToken()
  const location = useLocation()
  const navigate = useNavigate()

  if (!auth.user && !!token) {
    getUser(token)
      .then((user) => {
        auth.setUser(user)
      })
      .catch(() => {
        setToken(null as any)
        navigate(Slugs.HOME)
      })
    return <LoadingView />
  }

  return (
    <>
      {allowedRoles?.includes(auth?.user?.role) ? (
        <Outlet />
      ) : auth?.user?.id ? (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
      ) : (
        <Navigate to={Slugs.HOME} state={{ from: location }} replace />
      )}
    </>
  )
}

export default RequireAuth
