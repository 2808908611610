import React, { useEffect } from 'react'
import useAuth from '../../Hooks/useAuth'
import { Roles } from '../../Types/Role'
import DispensaryHomeView from './Components/DispensaryHomeView'
import CatalogHomeView from './Components/CatalogHomeView'
import { AccountValidation } from '../../Types/User'
import { Navigate } from 'react-router-dom'
import { Slugs } from '../../utils/constants'
import AdminHomeView from './Components/AdminHomeView'
import PublicHomeView from './Components/PublicHomeView'
import useToken from '../../Hooks/useToken'
import { getUser } from '../LoginView/actions'

const HomeView = () => {
  const { user } = useAuth()
  const auth = useAuth()

  const { loginUser, setToken } = useToken()
  useEffect(() => {
    if (loginUser.access_token) {
      getUser(loginUser.access_token)
        .then((user) => {
          auth.setUser(user)
        })
        .catch(() => {
          setToken(null as any)
        })
    }
  }, [])

  console.log('user', user, loginUser)
  if (!user) return <PublicHomeView />

  if (user.role === Roles.pharma) {
    if (user.adminValidated !== AccountValidation.VALIDATED) {
      return <Navigate to={Slugs.PROFILE} />
    }
    return <DispensaryHomeView />
  }

  if (user.role === Roles.provider) {
    return <CatalogHomeView />
  }

  if (user.role === Roles.admin) {
    return <AdminHomeView />
  }

  return <></>
}

export default HomeView
