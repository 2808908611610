import React, { useCallback, useState } from 'react'
import {
  BusinessCondition,
  BusinessConditionType,
} from '../../../Types/BusinessCondition'
import { Modal } from 'react-bootstrap'
import { shrinkText } from '../../../utils/helpers'
import { FiEdit, FiTrash } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { Slugs } from '../../../utils/constants'
import { deleteBusinessCondition } from '../actions'
import useToken from '../../../Hooks/useToken'
import { useHandleError } from '../../../Hooks/useHandleError'

type Props = {
  businessCondition: BusinessCondition
  onDelete: () => void
}

const BusinessConditionDetail: React.FC<Props> = ({
  businessCondition,
  onDelete,
}) => {
  const { loginUser } = useToken()

  const handleError = useHandleError()

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

  const handleDelete = () => {
    deleteBusinessCondition(loginUser, businessCondition.id)
      .then(() => {
        setDeleteModalOpen(false)
        onDelete()
      })
      .catch(handleError)
  }

  const navigate = useNavigate()
  const edit = useCallback(
    () => navigate(Slugs.BUSINESS_CONDITIONS_EDIT + '/' + businessCondition.id),
    [businessCondition.id]
  )

  return (
    <>
      <div className="business-cond-detail-container">
        <div className="text-start p-2 d-flex justify-content-between">
          <div className="cursor-pointer" onClick={edit}>
            <div className="flex">
              <div>
                <b>Pharmacie(s):&nbsp;</b>
              </div>
              <div>
                {businessCondition.dispensaries.length
                  ? businessCondition.dispensaries
                      .map((dispensary) =>
                        shrinkText(dispensary.business.businessName, 20)
                      )
                      .join('; ')
                  : 'Aucune pharmacie'}
              </div>
            </div>
            <div className="flex">
              <div>
                <b>Groupement(s):&nbsp;</b>
              </div>
              <div>
                {businessCondition.networks.length
                  ? businessCondition.networks
                      .map((network) => shrinkText(network, 20))
                      .join('; ')
                  : 'Aucun groupement'}
              </div>
            </div>
            <div className="flex">
              <div>
                <b>Marque:&nbsp;</b>
              </div>
              <div>{businessCondition.provider.name}</div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-5">
              <div>
                <div>
                  <b>Type: </b>
                </div>
                <div>
                  {businessCondition.type ===
                    BusinessConditionType.SHIPPING_COST &&
                    'Réduction sur les frais de port'}
                  {businessCondition.type ===
                    BusinessConditionType.PRODUCT_AMOUNT &&
                    'Réduction de prix unitaire de produit'}
                  {businessCondition.type === BusinessConditionType.FREE_UNIT &&
                    'Unité gratuite'}
                </div>
              </div>
              <div>
                <span>
                  <b>Paliers</b>
                </span>
                <div className="">
                  {businessCondition.type === BusinessConditionType.FREE_UNIT
                    ? `Produit offert pour ${businessCondition.freeUnitCap} produit(s) acheté(s)`
                    : businessCondition.steps.map((step) => (
                        <div key={step.id}>
                          <span>
                            {businessCondition.type ===
                            BusinessConditionType.SHIPPING_COST
                              ? `>${step.minimumOrderAmount}€ : ${step.discountRate}%`
                              : `>${step.minimumOrderAmount} achetés : ${step.discountRate}% | ${step.freeUnit} unité(s)`}
                          </span>
                        </div>
                      ))}
                </div>
              </div>
              {(businessCondition.type ==
                BusinessConditionType.PRODUCT_AMOUNT ||
                businessCondition.type == BusinessConditionType.FREE_UNIT) && (
                <div className="col-span-2 md:col-span-1">
                  <span>
                    <b>{businessCondition.products.length} produits</b>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div>
            <span className="me-3 pointer" onClick={edit}>
              <FiEdit color="#5ecc78" />
            </span>
            <span
              className="me-3 pointer"
              onClick={() => setDeleteModalOpen(true)}
            >
              <FiTrash color="#ff0000" />
            </span>
          </div>
        </div>
      </div>
      <Modal
        show={deleteModalOpen}
        onHide={() => setDeleteModalOpen(false)}
        onExit={() => setDeleteModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Supprimer la condition de vente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Voulez-vous vraiment supprimer cette condition de vente ?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => setDeleteModalOpen(false)}
          >
            Annuler
          </button>
          <button className="btn btn-success" onClick={handleDelete}>
            Supprimer
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default BusinessConditionDetail
