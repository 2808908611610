import React, { useEffect, useState } from 'react'
import { Provider } from '../../../Types/Provider'
import { useNavigate, useParams } from 'react-router-dom'
import ItemCard from '../../../Components/ItemCard'
import { Catalog, MinimalCatalog } from '../../../Types/Catalog'
import { getCatalog, getCatalogPublic, getMinimalCatalogs } from '../actions'
import useToken from '../../../Hooks/useToken'
import { Product } from '../../../Types/Product'
import { LoadingComponent } from '../../../Components/Loading'
import { SelectCatalog } from '../SelectCatalog'
import { Slugs } from '../../../utils/constants'
import useAuth from '../../../Hooks/useAuth'

import { useHandleError } from '../../../Hooks/useHandleError'
import { User } from '../../../Types/User'
import ClickableBanner from '../ClickableBanner'

const ShopViewPublic = () => {
  const [catalog, setCatalog] = useState<Catalog>({} as Catalog)
  const [allCatalogs, setAllCatalogs] = useState<MinimalCatalog[]>([])

  const [searchTerm, setSearchTerm] = useState<string>('')

  const navigate = useNavigate()
  const { user } = useAuth()

  const { catalogId } = useParams()
  const { loginUser } = useToken()
  const handleError = useHandleError()

  useEffect(() => {
    if (catalogId) {
      refresh()
    }
  }, [catalogId])

  const refresh = async () => {
    try {
      const fetchedCatalog = await getCatalogPublic(loginUser, catalogId)
      setCatalog(fetchedCatalog)
    } catch (error) {
      handleError(error)
      navigate('/404')
    }
  }

  useEffect(() => {
    const fetchAllCatalogs = async () => {
      try {
        const fetchedCatalogs = await getMinimalCatalogs(loginUser, false)
        setAllCatalogs(fetchedCatalogs)
      } catch (error) {
        console.log(error)
        handleError(error)
      }
    }

    fetchAllCatalogs()
  }, [])

  const updateCatalog = (catalogId: number) => {
    navigate(`${Slugs.SHOP}/${catalogId}`)
  }

  const handleSearch = (e: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setSearchTerm(e.target.value)
  }

  return (
    <div>
      <ClickableBanner
        imageUrl={catalog.providerLogo}
        onClick={() => {
          navigate(`${Slugs.SHOP}/${catalogId}${Slugs.INFO}`)
        }}
        buttonLabel="En savoir plus"
        title={allCatalogs.find((c) => c.id === Number(catalogId))?.name}
      />

      {/* Search bar */}
      <div className="search-bar-container mb-2">
        <input
          type="text"
          placeholder="Rechercher un produit..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-bar form-control"
        />
      </div>

      {catalog.id && allCatalogs.length > 0 ? (
        <>
          <div className="d-flex justify-content-between pe-xl-5 pb-3 items-center">
            <div>
              <SelectCatalog
                catalogs={allCatalogs}
                currentCatalog={allCatalogs.find(
                  (c) => c.id === Number(catalogId)
                )}
                updateCatalog={updateCatalog}
              />
            </div>
          </div>

          {catalog.providers.map((provider: Provider) => (
            <ProviderPart
              key={provider.id}
              provider={provider}
              user={user}
              refresh={refresh}
              searchTerm={searchTerm}
            />
          ))}
        </>
      ) : (
        <LoadingComponent />
      )}

      <div className="text-xs text-neutral-400 mb-3">
        Les prix et remises indiqués le sont à titre indicatifs. Philab ne peut
        garantir l'exactitude de ces informations. Pour en savoir plus, veuillez
        vous rapprocher du laboratoire.
      </div>
    </div>
  )
}

const ProviderPart: React.FC<{
  provider: Provider
  user: User
  refresh: () => void
  searchTerm: string
}> = ({ provider, user, refresh, searchTerm }) => {
  //const [searchTerm, setSearchTerm] = useState<string>('');

  const sortedProducts = provider.products.sort((a, b) =>
    a.name.localeCompare(b.name)
  )

  // Filtered products based on search term
  const filteredSortedProducts = sortedProducts
    .filter(
      (product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product?.ean13?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name))

  // Function to render products
  const renderProducts = (products: Product[]) => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
        {products.map((item: Product) => (
          <ItemCard
            key={item.id}
            // sales={itemsSteps}
            product={item}
            handleRefresh={refresh}
          />
        ))}
      </div>
    )
  }

  return (
    <div key={provider.id}>
      <h3 className="page-subtitle">{provider.name}</h3>
      {renderProducts(filteredSortedProducts)}
    </div>
  )
}

export default ShopViewPublic
