import useAuth from '../../Hooks/useAuth'

import ShopViewPublic from './Components/ShopViewPublic'
import ShopViewLoggedUser from './Components/ShopViewLoggedUser'

const ShopView = () => {
  const { user } = useAuth()

  console.log(user)
  if (!user) return <ShopViewPublic />

  return <ShopViewLoggedUser />
}

export default ShopView
