import React from 'react'
import { useNavigate } from 'react-router-dom'
import './style.css'
import { Catalog } from '../../Types/Catalog'
import { Slugs } from '../../utils/constants'

type Props = {
  catalog: Catalog
  isPreview?: boolean
}

const CatalogDisplay: React.FC<Props> = ({ catalog, isPreview }) => {
  let navigate = useNavigate()

  function handleClick(id: number) {
    console.log(`${Slugs.SHOP}/${id}`)
    if (!isPreview) {
      navigate(`${Slugs.SHOP}/${id}`)
    }
  }

  const urlPlaceholder =
    'https://images.unsplash.com/photo-1628088061698-e4f4cd2969bb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'

  const backgroundImage = {
    backgroundImage: `url(${
      catalog.coverPhoto
        ? catalog.coverPhoto?.includes('http')
          ? catalog.coverPhoto
          : `http://${catalog.coverPhoto}`
        : urlPlaceholder
    })`,
  }
  return (
    <div
      className="fd-card"
      style={backgroundImage}
      onClick={() => handleClick(catalog.id)}
    >
      <div className="fd-text-wrapper">
        <h1 className="fd-name">{catalog?.business?.businessName}</h1>
        <p className="fd-description">{catalog?.business?.legalStatus}</p>
      </div>
    </div>
  )
}

export default CatalogDisplay
