import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Provider } from '../../../Types/Provider'
import useToken from '../../../Hooks/useToken'
import { getAllCatalogs, searchProduct, searchProductPublic } from '../actions'
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap'
import CatalogDisplay from '../../../Components/FournisseurDisplay'
import { useHandleError } from '../../../Hooks/useHandleError'
import { getBasket } from '../../../Contexts/basket/actions'
import { BasketContext } from '../../../Contexts/basket/context'
import { ProductWithSteps } from '../../../Types/Product'
import ItemCard from '../../../Components/ItemCard'
import { BounceLoader } from 'react-spinners'
import { Catalog } from '../../../Types/Catalog'
import { getUser } from '../../LoginView/actions'

const PublicHomeView = () => {
  const [data, setData] = useState<Catalog[]>([])

  const { loginUser } = useToken()
  const handleError = useHandleError()

  useEffect(() => {
    getAllCatalogs(loginUser, false)
      .then((catalogs) => setData(catalogs))
      .catch(handleError)
  }, [])

  const [search, setSearch] = useState('')
  const [loadingProducts, setLoadingProducts] = useState(true)
  const [providersProducts, setProvidersProducts] =
    useState<{ provider: Provider; products: ProductWithSteps[] }[]>()
  const [shuffleCatalogs, setShuffledCatalogs] = useState<Catalog[]>([])
  const updateSearch = useMemo(() => {
    let waitingTimer: number
    return (search: string) => {
      setSearch(search)
      window.clearTimeout(waitingTimer)
      waitingTimer = window.setTimeout(() => {
        setProvidersProducts([])
        if (search.length >= 3) {
          setLoadingProducts(true)
          searchProductPublic(loginUser, search)
            .then(handleSearchProducts)
            .catch(handleError)
            .finally(() => setLoadingProducts(false))
        }
      }, 250)
    }
  }, [data])

  useEffect(() => {
    setShuffledCatalogs(sortAndShuffleCatalogs(data))
  }, [data])
  const handleSearchProducts = (products: ProductWithSteps[]) => {
    const providersProducts: {
      provider: Provider
      products: ProductWithSteps[]
    }[] = []
    for (const product of products) {
      const providerProducts = providersProducts.filter(
        (p) => p.provider.id == product.provider.id
      )
      if (providerProducts.length == 0) {
        providersProducts.push({
          provider: product.provider,
          products: [product],
        })
      } else {
        providerProducts[0].products.push(product)
      }
    }
    setProvidersProducts(providersProducts)
  }
  function shuffleArray(array: any[]) {
    const shuffled = [...array]
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]
    }
    return shuffled
  }

  function sortAndShuffleCatalogs(catalogs: any[]) {
    const zeroPriorityCatalogs = catalogs.filter(
      (catalog) => catalog.priorityIndex === 0
    )
    const nonZeroPriorityCatalogs = catalogs.filter(
      (catalog) => catalog.priorityIndex !== 0
    )

    // Sort non-zero priority catalogs in descending order
    nonZeroPriorityCatalogs.sort((a, b) => b.priorityIndex - a.priorityIndex)

    // Shuffle zero priority catalogs
    const shuffledZeroPriorityCatalogs = shuffleArray(zeroPriorityCatalogs)

    return [...nonZeroPriorityCatalogs, ...shuffledZeroPriorityCatalogs]
  }

  return (
    <>
      <FloatingLabel
        className="mb-1 login-form-label"
        label="Rechercher un produit..."
        controlId="search"
      >
        <Form.Control
          type="text"
          placeholder="Rechercher un produit..."
          autoComplete="off"
          className="login-form-input"
          onChange={(e) => updateSearch(e.target.value)}
          value={search}
          required
        />
      </FloatingLabel>
      {search.length >= 3 ? (
        loadingProducts ? (
          <div className="flex justify-center w-full py-3">
            <BounceLoader color="#5ecc78" />
          </div>
        ) : (
          providersProducts?.map((providerProducts) => (
            <div key={providerProducts.provider.id}>
              <div className="w-full flex items-center mt-5 mb-3">
                <div className="w-1/2 h-0.5 bg-neutral-200" />
                <span className="flex-shrink-0 mx-3 text-lg">
                  {providerProducts.provider.name}
                </span>
                <div className="w-1/2 h-0.5 bg-neutral-200" />
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
                {providerProducts.products.map((product) => (
                  <ItemCard
                    key={product.id}
                    product={product}
                    // sales={product.sales}
                  />
                ))}
              </div>
            </div>
          ))
        )
      ) : (
        <Row xs={1} md={2} xxl={3} className="gy-4">
          {shuffleCatalogs.map((catalog) => (
            <Col key={catalog.id}>
              <CatalogDisplay catalog={catalog} isPreview={false} />
            </Col>
          ))}
        </Row>
      )}
    </>
  )
}

export default PublicHomeView
